import {lazy} from 'react';
import {BasePage, FlattenPages, Page} from 'src/runtime/router/model';
// pages
const SettingsPageWrapper = lazy(() => import('src/runtime/router/components/SettingsPageWrapper'));
const CheckoutPage = lazy(() => import('src/jsx/checkout/checkout'));
const SiteEventsPage = lazy(() => import('src/jsx/siteevents/SiteEvents'));

const LandingemployersPage = lazy(() => import('src/jsx/landing/employers'));
const LandingJobSeekerPage = lazy(() => import('src/jsx/landing/jobSeeker'));

const ResourcesPage = lazy(() => import('src/jsx/resources/Resources'));
const ResourcePage = lazy(() => import('src/jsx/resources/Resource'));

const CompanyProfilePage = lazy(() => import('src/jsx/company/CompanyProfilePage'));
const CreateCompanyPage = lazy(() => import('src/jsx/company/account/create'));
const CreateCompanyPageInfo = lazy(() => import('src/jsx/company/account/parts/info'));
const CreateCompanyPageBrand = lazy(() => import('src/jsx/company/account/parts/brand'));
const CreateCompanyPageDetails = lazy(() => import('src/jsx/company/account/parts/details'));
const CreateCompanyPagePreview = lazy(() => import('src/jsx/company/account/parts/preview'));
const CompanyJobsListPage = lazy(() => import('src/jsx/company/profile/JobsList'));

const JobSeekerProfilePage = lazy(() => import('src/jsx/jobSeeker/profile.page'));
const CreateProfilePage = lazy(() => import('src/jsx/jobSeeker/create'));
const CreateProfilePagePersonal = lazy(() => import('src/jsx/jobSeeker/parts/personal'));
const CreateProfilePagePicture = lazy(() => import('src/jsx/jobSeeker/parts/picture'));
const CreateProfilePageResume = lazy(() => import('src/jsx/jobSeeker/parts/resume'));
const CreateProfilePageAbout = lazy(() => import('src/jsx/jobSeeker/parts/about'));
const CreateProfilePageExperience = lazy(() => import('src/jsx/jobSeeker/parts/experience'));
const CreateProfilePagePreview = lazy(() => import('src/jsx/jobSeeker/parts/preview'));

const JobsDashboardPage = lazy(() => import('src/jsx/jobs/Dashboard.page'));
const JobApplicantsPage = lazy(() => import('src/jsx/jobs/applicants/Applicants.page'));
const CreateJobPage = lazy(() => import('src/jsx/jobPost/create'));
const CreateJobPageDetails = lazy(() => import('src/jsx/jobPost/parts/details'));
const CreateJobPageLocation = lazy(() => import('src/jsx/jobPost/parts/location'));
const CreateJobPagePay = lazy(() => import('src/jsx/jobPost/parts/pay'));
const CreateJobPagePreferences = lazy(() => import('src/jsx/jobPost/parts/preferences'));
const CreateJobPagePreview = lazy(() => import('src/jsx/jobPost/parts/preview'));
const JobProfilePage = lazy(() => import('src/jsx/jobPost/profile.page'));

const MessagesPage = lazy(() => import('src/jsx/messages/messages'));

const CertificationPage = lazy(() => import('src/jsx/certification/certification'));

const TermsPage = lazy(() => import('src/jsx/terms/terms'));
const PrivacyPage = lazy(() => import('src/jsx/privacy/privacy'));
const PricingPage = lazy(() => import('src/jsx/pricing/pricing'));
const IconsPage = lazy(() => import('src/jsx/icons/Icons'));
const ContactPage = lazy(() => import('src/jsx/contact/contact'));
const FavoritesPage = lazy(() => import('src/jsx/favorites/favorites'));
// auth
const AuthPage = lazy(() => import('src/jsx/auth/AuthPage'));
const ResetPasswordPage = lazy(() => import('src/jsx/auth/ResetPasswordPage'));
const SignInPage = lazy(() => import('src/jsx/auth/SignInPage'));
const SignUpEmployerPage = lazy(() => import('src/jsx/auth/SignUpEmployerPage'));
const SignUpProfilePage = lazy(() => import('src/jsx/auth/SignUpProfilePage'));
const SignUpJobSeekerPage = lazy(() => import('src/jsx/auth/SignUpJobSeekerPage'));
const SignUpVerificationPage = lazy(() => import('src/jsx/auth/SignUpVerificationPage'));
const ButtonsDemoPage = lazy(() => import('src/jsx/internal/ButtonsDemoPage'));
const UploadPage = lazy(() => import('src/jsx/internal/UploadPage'));
// console
const ConsolePage = lazy(() => import('src/jsx/console/ConsolePage'));
const ConsoleAdminsPage = lazy(() => import('src/jsx/console/pages/ConsoleAdminsPage'));
const ConsoleSiteMapsPage = lazy(() => import('src/jsx/console/pages/ConsoleSiteMapsPage'));
const ConsoleLandingPage = lazy(() => import('src/jsx/console/pages/ConsoleLandingPage'));
const ConsoleUsersPage = lazy(() => import('src/jsx/console/pages/ConsoleUsersPage'));
// search
const SearchCandidatesPage = lazy(() => import('src/jsx/search-jobseekers/search'));
const SearchJobsPage = lazy(() => import('src/jsx/search-jobs/search'));
//debug
const ResourceLinksPage = lazy(() => import('src/jsx/debug/ResourcesLinksPage'));

export const pagelist = {
    landingJobSeeker: {
        path: '',
        title: 'Find Beauty and Spa Jobs Near You - Hair Stylist, Nail Tech, Esthetician | Hive.ing',
        component: LandingJobSeekerPage,
    },
    landingEmployers: {
        path: 'for-employers',
        title: 'Find Beauty and Spa Jobs Near You - Hair Stylist, Nail Tech, Esthetician | Hive.ing',
        component: LandingemployersPage,
    },
    debugResourcesLinks: {
        path: 'debug/resources/:uri',
        title: 'Find Beauty and Spa Jobs Near You - Hair Stylist, Nail Tech, Esthetician | Hive.ing',
        component: ResourceLinksPage,
    },
    auth: {
        path: 'WRAPPER',
        title: 'Login | hive.ing',
        component: AuthPage,
        children: {
            signUpEmployer: {
                path: 'sign-up/employer',
                title: 'Sign Up For Employers | hive.ing',
                component: SignUpEmployerPage,
            },
            signUpJobSeeker: {
                path: 'sign-up/job-seeker',
                title: 'Sign Up | hive.ing',
                component: SignUpJobSeekerPage,
            },
            signUpVerification: {
                path: 'sign-up/verification',
                title: 'Verification | hive.ing',
                component: SignUpVerificationPage,
            },
            signUpProfile: {
                path: 'sign-up/profile',
                title: 'Sign Up | hive.ing',
                component: SignUpProfilePage,
            },
            resetPassword: {
                path: 'reset-password',
                title: 'Reset Password | hive.ing',
                component: ResetPasswordPage,
            },
            signIn: {
                path: 'login',
                title: 'Sign In | hive.ing',
                component: SignInPage,
            },
        },
    },
    console: {
        path: 'console',
        title: 'Console | hive.ing',
        component: ConsolePage,
        children: {
            consoleLanding: {
                path: '',
                title: 'Console | hive.ing',
                component: ConsoleLandingPage,
            },
            consoleUsers: {
                path: 'users',
                title: 'Console Users | hive.ing',
                component: ConsoleUsersPage,
            },
            consoleAdmins: {
                path: 'admins',
                title: 'Console Admins | hive.ing',
                component: ConsoleAdminsPage,
            },
            consoleSiteMaps: {
                path: 'sitemaps',
                title: 'Console SiteMaps',
                component: ConsoleSiteMapsPage,
            },
        },
    },
    searchCandidates: {
        path: 'search/candidates',
        title: 'Search candidates | hive.ing',
        component: SearchCandidatesPage,
    },
    searchJobs: {
        path: 'search/jobs',
        title: 'Search Beauty, Spa, Nail Tech, Hair Stylist & Massage Jobs',
        component: SearchJobsPage,
    },
    checkout: {
        path: '/checkout',
        title: 'Checkout | hive.ing',
        component: CheckoutPage,
    },
    SingleCompany: {
        path: 'companies/:company_uri',
        title: 'Company | hive.ing',
        component: CompanyProfilePage,
    },
    CompanyJobsList: {
        path: 'companies/:company_uri/jobs',
        title: 'Jobs | hive.ing',
        component: CompanyJobsListPage,
    },
    companyJobsDashboard: {
        path: 'companies/:company_uri/dashboard',
        title: 'Jobs Dashboard | hive.ing',
        component: JobsDashboardPage,
    },
    companyJobApplicants: {
        path: 'companies/:company_uri/jobs/:job_guid/applicants',
        title: 'Applicants | hive.ing',
        component: JobApplicantsPage,
    },
    companyEdit: {
        path: ['companies/:company_uri/edit', 'companies/create'],
        title: 'Company | hive.ing',
        component: CreateCompanyPage,
        children: {
            companyEditInfo: {
                path: '',
                title: 'Company | hive.ing',
                component: CreateCompanyPageInfo,
            },
            companyEditDetails: {
                path: 'details',
                title: 'Company details | hive.ing',
                component: CreateCompanyPageDetails,
            },
            companyEditBranding: {
                path: 'branding',
                title: 'Logo & Branding | hive.ing',
                component: CreateCompanyPageBrand,
            },
            companyEditPreview: {
                path: 'preview',
                title: 'Preview | hive.ing',
                component: CreateCompanyPagePreview,
            },
        },
    },
    SingleJob: {
        path: ['jobs', 'jobs/:uri'],
        title: 'Job Posting | hive.ing',
        component: JobProfilePage,
    },
    jobEdit: {
        path: ['jobs/:guid/edit', 'jobs/create'],
        title: 'Job Posting | hive.ing',
        component: CreateJobPage,
        children: {
            jobEditDetails: {
                path: '',
                title: 'Job details | hive.ing',
                component: CreateJobPageDetails,
            },
            jobEditLocation: {
                path: 'location',
                title: 'Location | hive.ing',
                component: CreateJobPageLocation,
            },
            jobEditPay: {
                path: 'pay',
                title: 'Pay & Benefits | hive.ing',
                component: CreateJobPagePay,
            },
            jobEditPreferences: {
                path: 'preferences',
                title: 'Preferences | hive.ing',
                component: CreateJobPagePreferences,
            },
            jobEditPreview: {
                path: 'preview',
                title: 'Preview | hive.ing',
                component: CreateJobPagePreview,
            },
        },
    },
    SingleProfile: {
        path: ['profile', 'profile/:guid'],
        title: 'Profile | hive.ing',
        component: JobSeekerProfilePage,
    },
    favorites: {
        path: 'favorites',
        title: 'Favorites | hive.ing',
        component: FavoritesPage,
    },
    profileEdit: {
        path: ['profile/edit'],
        title: 'Profile | hive.ing',
        component: CreateProfilePage,
        children: {
            profileEditPersonal: {
                path: '',
                title: 'Personal Information | hive.ing',
                component: CreateProfilePagePersonal,
            },
            profileEditPicture: {
                path: 'picture',
                title: 'Profile picture | hive.ing',
                component: CreateProfilePagePicture,
            },
            profileEditResume: {
                path: 'resume',
                title: 'Beauty Resume | hive.ing',
                component: CreateProfilePageResume,
            },
            profileEditAbout: {
                path: 'about',
                title: 'About You | hive.ing',
                component: CreateProfilePageAbout,
            },
            profileEditExperience: {
                path: 'experience',
                title: 'Work Experience | hive.ing',
                component: CreateProfilePageExperience,
            },
            profileEditPreview: {
                path: 'preview',
                title: 'Preview | hive.ing',
                component: CreateProfilePagePreview,
            },
        },
    },
    messages: {
        path: ['messages', 'messages/:guid'],
        title: 'Messages | hive.ing',
        component: MessagesPage,
    },
    certification: {
        path: 'impact',
        title: 'Labor and Human Trafficking Awareness and Prevention in the Beauty Industry | Hive.ing',
        component: CertificationPage,
    },
    terms: {
        path: 'terms',
        title: 'Terms of use | hive.ing',
        component: TermsPage,
    },
    privacy: {
        path: 'privacy',
        title: 'Privacy Policy | hive.ing',
        component: PrivacyPage,
    },
    contact: {
        path: 'contact-us',
        title: 'Contact Us for Beauty and Hair Stylist Industry Jobs | Hive.ing',
        component: ContactPage,
    },
    pricing: {
        path: 'pricing',
        title: 'Our Job Package Pricing | Hive.ing',
        component: PricingPage,
    },
    resources: {
        path: 'resources',
        title: 'Beauty Industry Resources - Trends, Tips, Education | hive.ing',
        component: ResourcesPage,
    },
    resource: {
        path: 'resources/:uri',
        title: 'Resource | hive.ing',
        component: ResourcePage,
    },
    settings: {
        path: 'settings',
        title: 'Account | hive.ing',
        component: SettingsPageWrapper,
        children: {
            settingsPersonal: {
                path: 'personal',
                title: 'Personal | hive.ing',
                component: SettingsPageWrapper,
            },
            settingsSecurity: {
                path: 'security',
                title: 'Login & Security | hive.ing',
                component: SettingsPageWrapper,
            },
            settingsBilling: {
                path: 'billing',
                title: 'Billing and Payments | hive.ing',
                component: SettingsPageWrapper,
            },
            settingsBillingPromotions: {
                path: 'billing/promotions',
                title: 'Promotions | hive.ing',
                component: SettingsPageWrapper,
            },
            settingsSingleCompany: {
                path: 'companies/:company_uri',
                title: 'Company Settings | hive.ing',
                component: SettingsPageWrapper,
            },
            settingsSingleCompanyInfo: {
                path: 'companies/:company_uri/info',
                title: 'Company Info | hive.ing',
                component: SettingsPageWrapper,
            },
            //TODO:CompanyAccessRemove - search for this todo TMP solution to hide company team access page
            // settingsSingleCompanyAccess: {
            //     path: 'companies/:company_uri/access',
            //     title: 'Team Access',
            //     component: SettingsPageWrapper,
            // },
            settingsSingleCompanySubscriptions: {
                path: 'companies/:company_uri/subscriptions',
                title: 'Subscriptions & Promotions | hive.ing',
                component: SettingsPageWrapper,
            },
            settingsSingleCompanyInvoices: {
                path: 'companies/:company_uri/subscriptions/invoices',
                title: 'Invoices | hive.ing',
                component: SettingsPageWrapper,
            },
        },
    },
    siteevents: {
        path: 'events',
        title: 'Beauty Industry Events - Training and Education | hive.ing',
        component: SiteEventsPage,
    },
    icons: {
        path: 'icons',
        title: 'Icons | hive.ing',
        component: IconsPage,
    },
    buttons: {
        path: 'buttons',
        title: 'Buttons | hive.ing',
        component: ButtonsDemoPage,
    },
    upload: {
        path: 'upload',
        title: 'Upload files | hive.ing',
        component: UploadPage,
    },
} satisfies Record<string, Page<any>>;

// GENERAL TYPES, DO NOT CHANGE
export interface BasePageWithID extends BasePage {
    id: PageIDs;
}

export type PageIDs = FlattenPages<typeof pagelist>;
export type PagelistMap = Record<PageIDs, BasePageWithID>;
export type PageFetcehrsMap = Record<PageIDs, (() => Promise<any>) | undefined>;

function flattenPages(list: PagelistMap, pages: Record<string, Page<any, any>>): PagelistMap {
    Object.entries(pages).forEach(([key, page]) => {
        const pageKey = key as PageIDs;
        if (list[pageKey]) {
            console.error(`Second declaration for page key: [${key}]`);
        }

        list[pageKey] = {
            id: pageKey,
            title: page.title,
            path: page.path,
            caseSensitive: page.caseSensitive,
            settings: page.settings,
        };

        if (page.children) {
            list = flattenPages(list, page.children);
        }
    });

    return list;
}

export const pagemap = flattenPages({} as PagelistMap, pagelist);

export function getPagePreFetchers(list: PageFetcehrsMap, pages: Record<string, Page<any, any>>): PageFetcehrsMap {
    Object.entries(pages).forEach(([key, page]) => {
        const pageKey = key as PageIDs;
        list[pageKey] = page.preFetcher;

        if (page.children) {
            list = getPagePreFetchers(list, page.children);
        }
    });

    return list;
}
